import { Ref, ref } from 'vue'

interface UseApp {
  inited: Ref<boolean>
  showHeader: Ref<boolean>
}

const inited = ref(false)
const showHeader = ref(true)

export const useApp = (): UseApp => {
  return {
    inited,
    showHeader,
  }
}
