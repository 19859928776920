import { instance } from '@/services/avik-api-gateway'
import { DailyReportTrack } from '@/types/daily-report-deviation'
import { AxiosResponse } from 'axios'

export function getAllDailyReportDeviation(
  date: string
): Promise<AxiosResponse<DailyReportTrack[]>> {
  return instance.get(`/daily-report/deviation`, {
    params: {
      date,
    },
  })
}
