<template>
  <AppChangePasswordModal v-if="changePasswordModal.state.show" />
  <AppConfirmLogout v-if="confirmLogoutModal.state.show" />
  <q-btn-dropdown no-caps flat :label="email" content-class="no-border-radius">
    <q-list style="min-width: 100px">
      <q-item clickable v-close-popup @click="routerPush('projects')">
        <q-item-section avatar>
          <q-icon size="xs" name="mdi-view-dashboard" />
        </q-item-section>
        <q-item-section>Välj projekt</q-item-section>
      </q-item>
      <q-item
        clickable
        v-close-popup
        @click="changePassword"
        v-if="profile.provider !== 'sso' && !profile.preventPasswordReset"
      >
        <q-item-section avatar>
          <q-icon size="xs" name="mdi-lock-reset" />
        </q-item-section>
        <q-item-section>Byt lösenord</q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="onClickLogout">
        <q-item-section avatar>
          <q-icon size="xs" name="mdi-logout-variant" />
        </q-item-section>
        <q-item-section>Logga ut</q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import { useProfile } from '@/composable/useProfile'
import { defineComponent, provide } from 'vue'
import { routerPush } from '@/router'
import { useModal } from '@/composable/useModal'
import AppChangePasswordModal from './AppChangePasswordModal.vue'
import AppConfirmLogout from './AppConfirmLogout.vue'

export default defineComponent({
  name: 'AppAccountDropdown',

  components: {
    AppChangePasswordModal,
    AppConfirmLogout,
  },

  setup() {
    const changePasswordModal = useModal()
    const confirmLogoutModal = useModal()
    provide('confirm-logout', confirmLogoutModal)
    provide('change-password-modal', changePasswordModal)
    const { email, signOut, profile } = useProfile()
    function changePassword() {
      changePasswordModal.openModal()
    }

    const onClickLogout = () => {
      confirmLogoutModal.openModal()
    }

    return {
      email,
      signOut,
      routerPush,
      changePassword,
      changePasswordModal,
      profile,
      confirmLogoutModal,
      onClickLogout,
    }
  },
})
</script>
