import { reactive } from 'vue'

interface UseLoading {
  state: Loading
}

export interface Loading {
  get: boolean
  getAll: boolean
  delete: boolean
  create: boolean
  update: boolean
}

export function useLoading(): UseLoading {
  const state = reactive<Loading>({
    get: false,
    getAll: false,
    delete: false,
    create: false,
    update: false,
  })

  return {
    state,
  }
}
