import { Ref, ref, watch } from 'vue'
import { useProfile } from './useProfile'
import { getUserSettings } from '@/api/user-settings/getUserSettings'
import { postUserSettings } from '@/api/user-settings/postUserSettings'

interface Track {
  label: string
  visible: boolean
}

interface EventViewFilter {
  filterPenaltyAssessment: boolean
  filterAutoDelay: boolean
  filterDeleted: boolean
  activeEventTab: string | null
  roles: string[]
}

interface DelayViewFilter {
  tracks: Track[]
}

interface DailyReportViewExternalV2ViewFilter {
  eventTypes: string[]
  tracks: Track[]
  show_percent: string
  expanded_traffic_row: {
    [key: string]: boolean
  }
}

export interface UserSettingsFilter {
  [project: string]: {
    event: EventViewFilter
    delay: DelayViewFilter
    dailyReportExternalV2: DailyReportViewExternalV2ViewFilter
    dailyReportInternal: DailyReportViewExternalV2ViewFilter
  }
}

interface UseUserSettings {
  settings: Ref<UserSettingsFilter>
  fetchUserSettings: () => Promise<void>
}

const defaultFilter = () => {
  const { projects } = useProfile()

  const settings: UserSettingsFilter = {}

  for (const project of projects.value) {
    settings[project.name] = {
      event: {
        activeEventTab: null,
        filterPenaltyAssessment: false,
        filterAutoDelay: false,
        filterDeleted: false,
        roles: [],
      },
      delay: {
        tracks: [],
      },
      dailyReportExternalV2: {
        eventTypes: [],
        tracks: [],
        show_percent: 'percentage',
        expanded_traffic_row: {},
      },
      dailyReportInternal: {
        eventTypes: [],
        tracks: [],
        show_percent: 'percentage',
        expanded_traffic_row: {},
      },
    }
  }

  return settings
}

const settings = ref<UserSettingsFilter>({})
const inited = ref(false)

const registerSettingsWatcher = () => {
  watch(
    settings.value,
    (value) => {
      postUserSettings(value)
    },
    {
      deep: true,
    }
  )
}

export const useUserSettings = (): UseUserSettings => {
  const fetchUserSettings = async () => {
    if (inited.value) return
    inited.value = true
    const { data } = await getUserSettings()
    settings.value = defaultFilter()
    if (!data || !Object.values(data).length) {
      return registerSettingsWatcher()
    }
    const { projects } = useProfile()
    for (const project of projects.value) {
      const projectName = project.name as string
      if (data[projectName] && Object.values(data[projectName]).length) {
        const views = Object.keys(data[projectName])
        for (const view of views) {
          if (view === 'event') {
            settings.value[projectName].event = data[projectName].event
          }
          if (view === 'delay') {
            settings.value[projectName].delay = data[projectName].delay
          }
          if (view === 'dailyReportExternalV2') {
            settings.value[projectName].dailyReportExternalV2 =
              data[projectName].dailyReportExternalV2
          }
          if (view === 'dailyReportInternal') {
            settings.value[projectName].dailyReportInternal =
              data[projectName].dailyReportInternal
          }
        }
      }
    }
    registerSettingsWatcher()
  }

  return {
    fetchUserSettings,
    settings,
  }
}
