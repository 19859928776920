<template>
  <q-toolbar class="q-py-md">
    <AppLogo @click="() => routerPush('projects')" />
    <q-toolbar-title>Avik</q-toolbar-title>
    <q-badge color="grey-8" class="q-pa-sm">{{
      currentProject.description
    }}</q-badge>

    <q-space />
    <AppAccountDropdown />
  </q-toolbar>
</template>

<script lang="ts">
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import { defineComponent } from 'vue'
import { useProfile } from '@/composable/useProfile'
import { routerPush } from '@/router'

import AppLogo from '@/components/AppLogo.vue'

export default defineComponent({
  name: 'AppTopBar',

  components: {
    AppLogo,
    AppAccountDropdown,
  },

  setup() {
    const { currentProject } = useProfile()

    return { currentProject, routerPush }
  },
})
</script>
