import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'

export interface CloseEventBody {
  eventUuid: string
  closedAt: string
}

export function closeEvent(
  body: CloseEventBody
): Promise<AxiosResponse<unknown>> {
  return instance.post(`/closed-event`, body)
}
