<template>
  <q-dialog v-model="showErrorModal" :persistent="false">
    <q-card style="min-width: 350px" square>
      <q-card-section class="flex items-center">
        <q-icon size="sm" name="mdi-alert-circle-outline" class="q-mr-md" />
        <div class="text-h6">Ett fel uppstod</div>
      </q-card-section>

      <q-separator />

      <template v-if="errorApplication">
        <q-card-section class="q-pb-none text-subtitle2"
          >Application</q-card-section
        >
        <q-card-section class="q-pb-none" v-if="errorApplication.vm">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Component: </strong>{{ errorApplication.vm }}
          </div>
        </q-card-section>

        <q-card-section class="q-pb-none" v-if="errorApplication.info">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Info: </strong>{{ errorApplication.info }}
          </div>
        </q-card-section>

        <q-card-section class="q-pb-none" v-if="errorApplication.error">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Error: </strong>{{ errorApplication.error }}
          </div>
        </q-card-section>
      </template>

      <template v-if="errorRequest">
        <q-separator class="q-mt-md" />
        <q-card-section class="q-pb-none text-subtitle2"
          >Request</q-card-section
        >
        <q-card-section class="q-pb-none" v-if="errorRequest.url">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>URL: </strong>{{ errorRequest.url }}
          </div>
        </q-card-section>

        <q-card-section class="q-pb-none" v-if="errorRequest.method">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Method: </strong>{{ errorRequest.method }}
          </div>
        </q-card-section>

        <q-card-section class="q-pb-none" v-if="errorRequest.data">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Data: </strong>{{ errorRequest.data }}
          </div>
        </q-card-section>
      </template>

      <template v-if="errorResponse">
        <q-separator class="q-mt-md" />
        <q-card-section class="q-pb-none text-subtitle2"
          >Response</q-card-section
        >
        <q-card-section class="q-pb-none" v-if="errorResponse.status">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Status: </strong>{{ errorResponse.status }}
          </div>
        </q-card-section>

        <q-card-section class="q-pb-none" v-if="errorResponse.statusText">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Message: </strong>{{ errorResponse.statusText }}
          </div>
        </q-card-section>

        <q-card-section class="q-pb-none" v-if="errorResponse.data">
          <div :class="[$style.docCode, $style.docCodeInner]">
            <strong>Data: </strong>{{ errorResponse.data }}
          </div>
        </q-card-section>
      </template>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat label="Stäng" color="negative" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useError } from '@/composable/useError'

export default defineComponent({
  name: 'AppErrorModal',

  setup() {
    return useError()
  },
})
</script>

<style lang="scss" module>
.docCode {
  border-radius: 4px;
  overflow: visible;
  padding: 0;
}

.docCodeInner {
  word-wrap: normal;
  background-color: #fdfdfd;
  color: #424242;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 12px;
  -webkit-hyphens: none;
  hyphens: none;
  line-height: 1.5;
  margin: 0;
  position: relative;
  -moz-tab-size: 1;
  tab-size: 1;
  text-align: left;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  padding: 4px;
  background-color: #f5f5f5;
  display: block;
  height: inherit;
  max-height: inherit;
  overflow: auto;
}
</style>
