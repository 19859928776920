import { instance } from '@/services/avik-api-gateway'
import { DeviationCancel } from '@/types/deviation-cancel'
import { AxiosResponse } from 'axios'

export function updateDeviationCancel(
  body: Partial<DeviationCancel>,
  deviationCancelUuid: string
): Promise<AxiosResponse<DeviationCancel>> {
  return instance.put(`/deviation-cancel/${deviationCancelUuid}`, body)
}

export function updateDeviationCancelDistance(
  deviationCancelUuid: string,
  body: { distance: number }
): Promise<AxiosResponse<DeviationCancel>> {
  return instance.put(`/deviation-cancel/${deviationCancelUuid}/distance`, body)
}
