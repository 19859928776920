<template>
  <q-dialog :model-value="true" :persistent="true">
    <q-card style="min-width: 350px" square>
      <q-form ref="formRef" autocomplete="off" greedy>
        <q-card-section>
          <div class="text-h6">Logga ut</div>
        </q-card-section>

        <q-card-section>
          <p>Är du säker på att du vill logga ut?</p>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-py-sm">
          <q-toggle
            v-model="emptyFilter"
            label="Töm sparade filter inställningar"
          />
        </q-card-section>

        <q-separator />

        <q-card-actions align="right">
          <q-btn
            flat
            type="button"
            label="Avbryt"
            @click="modal.closeModal()"
          />
          <q-btn
            flat
            label="Bekräfta"
            type="submit"
            color="primary"
            @click="onSignOut"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { useProfile } from '@/composable/useProfile'
import { defineComponent, inject, ref } from 'vue'
import { FILTER_TRACKS_LOCALSTORAGE_KEY } from '@/composable/useDailyReport'
import { useFilterLocalStorage } from '@/composable/useFilterLocalStorage'

export default defineComponent({
  name: 'AppConfirmLogout',

  setup() {
    const modal = inject<UseModal<unknown>>(
      'confirm-logout'
    ) as UseModal<unknown>

    const emptyFilter = ref(false)
    const filterStorage = useFilterLocalStorage()

    const { signOut } = useProfile()

    const onSignOut = () => {
      if (emptyFilter.value) {
        window.localStorage.removeItem(FILTER_TRACKS_LOCALSTORAGE_KEY)
        filterStorage.clear()
      }
      signOut()
    }
    return { modal, onSignOut, emptyFilter }
  },
})
</script>
