import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { EventTableItem } from '@/types/event'

export interface FetchParams {
  from: string
  to: string
}

export function getEvents(
  params: FetchParams
): Promise<AxiosResponse<EventTableItem[]>> {
  return instance.get(`/event`, {
    params,
    transformResponse: (data) => {
      if (data) {
        return JSON.parse(data).map((x: Record<string, string>) => ({
          ...x,
          createdByRoles: (x.createdByRoles || '')
            .split(',')
            .map((x) => x.trim())
            .filter(Boolean),
        }))
      }
      return data
    },
  })
}
