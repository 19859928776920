import axios from 'axios'
import { CONFIG } from '../config'
import { useProfile } from '@/composable/useProfile'
import { useError } from '@/composable/useError'

const { getToken, isSignedIn, signOut, getCurrentProject } = useProfile()
const { axiosErrorHandler } = useError()
const CancelToken = axios.CancelToken

export const instance = axios.create({
  baseURL: CONFIG.API_HOST,
  headers: {},
})

instance.interceptors.request.use(async (config) => {
  const signedIn = await isSignedIn()

  if (!signedIn) {
    signOut()
    return {
      ...config,
      cancelToken: new CancelToken((cancel) => cancel('Not signed in')),
    }
  }

  const headers: Record<string, string> = {
    'reges-avik-project': getCurrentProject()?.name || '',
    Authorization: `Bearer ${getToken()}`,
  }

  config.headers = {
    ...config.headers,
    ...headers,
  }
  return config
}, axiosErrorHandler)

instance.interceptors.response.use((response) => response, axiosErrorHandler)
