import { getActiveEvents } from '@/api/status/getActiveEvents'
import { getActiveProposals } from '@/api/status/getActiveProposals'
import { StatusActiveEvent } from '@/types/status-active-event'
import { StatusActiveProposal } from '@/types/status-active-proposal'
import { computed, ComputedRef, ref, Ref } from 'vue'
import { useDeviationCancelProposal } from './useDeviationCancelProposal'

const { deviationsWithMissingTrv } = useDeviationCancelProposal()

interface LoadingState {
  event: {
    getAll: boolean
  }
  proposal: {
    getAll: boolean
  }
}

interface DataState {
  activeEvents: number
  activeProposals: StatusActiveProposal
}

interface UseStatusBadgeCount {
  fetchActiveEvents: () => Promise<StatusActiveEvent>
  fetchActiveProposals: () => Promise<StatusActiveProposal>
  loading: Ref<LoadingState>
  data: Ref<DataState>
  total: ComputedRef<number>
}

const loading = ref<LoadingState>({
  event: {
    getAll: false,
  },
  proposal: {
    getAll: false,
  },
})

const data = ref<DataState>({
  activeEvents: 0,
  activeProposals: {
    deviationCancel: 0,
    deviationDelay: 0,
    deviationVehicle: 0,
    total: 0,
  },
})

const total = computed(() => {
  return Object.entries(data.value.activeProposals).reduce(
    (acc, [key, val]) => {
      if (key === 'total') return acc
      return acc + val
    },
    deviationsWithMissingTrv.value.filter((x) => x.missingTrv).length
  )
})

export function useStatusBadgeCount(): UseStatusBadgeCount {
  function fetchActiveEvents() {
    loading.value.event.getAll = true
    return new Promise<StatusActiveEvent>((resolve, reject) => {
      getActiveEvents()
        .then(({ data: activeEventCount }) => {
          data.value.activeEvents = activeEventCount.activeEvents
          resolve(activeEventCount)
        })
        .catch(reject)
        .finally(() => {
          loading.value.event.getAll = false
        })
    })
  }

  function fetchActiveProposals() {
    loading.value.event.getAll = true
    return new Promise<StatusActiveProposal>((resolve, reject) => {
      getActiveProposals()
        .then(({ data: activeProposals }) => {
          data.value.activeProposals = activeProposals
          resolve(activeProposals)
        })
        .catch(reject)
        .finally(() => {
          loading.value.event.getAll = false
        })
    })
  }

  return {
    fetchActiveEvents,
    fetchActiveProposals,
    data,
    loading,
    total,
  }
}
