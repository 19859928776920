
import { useProfile } from '@/composable/useProfile'
import { defineComponent, provide } from 'vue'
import { routerPush } from '@/router'
import { useModal } from '@/composable/useModal'
import AppChangePasswordModal from './AppChangePasswordModal.vue'
import AppConfirmLogout from './AppConfirmLogout.vue'

export default defineComponent({
  name: 'AppAccountDropdown',

  components: {
    AppChangePasswordModal,
    AppConfirmLogout,
  },

  setup() {
    const changePasswordModal = useModal()
    const confirmLogoutModal = useModal()
    provide('confirm-logout', confirmLogoutModal)
    provide('change-password-modal', changePasswordModal)
    const { email, signOut, profile } = useProfile()
    function changePassword() {
      changePasswordModal.openModal()
    }

    const onClickLogout = () => {
      confirmLogoutModal.openModal()
    }

    return {
      email,
      signOut,
      routerPush,
      changePassword,
      changePasswordModal,
      profile,
      confirmLogoutModal,
      onClickLogout,
    }
  },
})
