import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationCancel } from '@/types/deviation-cancel'

interface FetchParams {
  from: string
  to: string
}

let abortController: AbortController

export function getDeviationCancels(
  params: FetchParams
): Promise<AxiosResponse<DeviationCancel[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }
  return instance.get(`/deviation-cancel`, {
    params,
    signal: abortController.signal,
  })
}
