
import AppErrorModal from '@/components/AppErrorModal.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',

  components: {
    AppErrorModal,
  },
})
