import { render } from "./AppChangePasswordModal.vue?vue&type=template&id=2e643776"
import script from "./AppChangePasswordModal.vue?vue&type=script&lang=ts"
export * from "./AppChangePasswordModal.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./AppChangePasswordModal.vue?vue&type=style&index=0&id=2e643776&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QForm,QCardSection,QIcon,QSeparator,QInput,QSlideTransition,QBanner,QCardActions,QBtn});
