<template>
  <div v-if="showData" class="statusbar">
    <div class="container">
      <div style="display: flex; gap: 1em">
        <div>
          <q-icon size="sm" name="mdi-information-outline"></q-icon>
        </div>
        <div class="title">{{ data[0].title }}:</div>
        <div class="body">{{ data[0].body }}</div>
      </div>
      <div class="close" @click="closeBanner">
        <q-icon size="sm" name="mdi-close"></q-icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStatusbar } from '@/composable/useStatusbar'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'AppStatusbar',

  setup() {
    const { currentProject } = useProfile()
    const { data, showBanner, closeBanner } = useStatusbar(
      ['gotalandstag', 'krosatag'].includes(currentProject.value?.name || '')
    )

    const showData = computed(() => {
      return showBanner.value && data.value.length > 0
    })

    return {
      data,

      closeBanner,
      showData,
    }
  },
})
</script>

<style lang="scss" scoped>
.statusbar {
  background-color: #ffcc00;
  color: black;
  min-height: 40px;
}

.title {
  font-weight: bolder;
}

.container {
  margin: auto;
  padding: 1em;
  min-height: 100%;
  max-width: 80%;
  display: flex;
  gap: 1em;
  min-height: 40px;
  justify-content: center;
}

.close {
  cursor: pointer;
}
</style>
