<template>
  <q-card style="max-width: 100%; width: 400px">
    <q-form ref="formRef" @submit.prevent="onSubmit" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6"
        >Exportera rålista</q-card-section
      >

      <q-separator />

      <q-card-section class="q-pa-none">
        <div class="row q-pa-md">
          <div class="row">
            <div class="col-6">
              <AppFieldDate
                label="Från datum"
                v-model="model.from"
                :rules="[validDateRule]"
              />
            </div>
            <div class="col-6">
              <AppFieldDate
                label="Till datum"
                v-model="model.to"
                :rules="[validDateRule]"
              />
            </div>
          </div>
          <q-slide-transition>
            <div class="row items-center text-red-6" v-if="!validDate">
              <q-icon
                name="mdi-information-outline"
                class="q-mr-sm"
                size="xs"
              />
              Slut datum måste vara samma eller efter start datum!
            </div>
          </q-slide-transition>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="$emit('close')"
          type="button"
          :disable="exporting"
        />
        <q-btn
          label="Exportera"
          type="submit"
          color="primary"
          :loading="exporting"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import {
  validDateRule,
  required as requiredRule,
} from '@/common/formValidationRules'
import { QForm } from 'quasar'
import { computed, defineComponent, ref } from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { format, addDays, isAfter, isEqual } from 'date-fns'
import { exportExcel } from '@/services/export-excel'
import { exportEventRaw, ExportEventParamsRaw } from '@/api/export/exportEvent'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'ExportRawListModal',

  components: {
    AppFieldDate,
  },

  emits: ['close'],

  setup(props, { emit }) {
    const model = ref<ExportEventParamsRaw>({
      from: format(new Date(), 'yyyy-MM-dd'),
      to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    })
    const { currentProject } = useProfile()
    const exporting = ref(false)

    const formRef = ref<QForm | null>(null)

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      exporting.value = true

      const { data } = await exportEventRaw(model.value)

      const fileName = [
        'Rålista',
        currentProject?.value?.name || '',
        model.value.from,
        model.value.to,
      ].join(' ')
      const exportData = {
        name: fileName,
        sheets: [
          {
            name: 'Inställt tåg',
            data: data?.deviationCancel || [],
          },
          {
            name: 'Fordonsavvikelse',
            data: data?.deviationVehicle || [],
          },
          {
            name: 'Försening',
            data: data?.deviationCrew || [],
          },
          {
            name: 'Bemanning',
            data: data?.deviationDelay || [],
          },
        ],
      }

      exportExcel(exportData)

      exporting.value = false

      emit('close')
    }

    const validDate = computed(() => {
      return (
        isAfter(new Date(model.value.to), new Date(model.value.from)) ||
        isEqual(new Date(model.value.to), new Date(model.value.from))
      )
    })
    return {
      exporting,
      onSubmit,
      formRef,
      validDateRule,
      requiredRule,
      model,
      validDate,
    }
  },
})
</script>
