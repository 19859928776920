import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DailyReportTrafficV2 } from '@/types/daily-report-traffic-v2'

export interface DailyReportTrafficV2Payload {
  headers: {
    label: string
    tag: string
  }[]
  rows: DailyReportTrafficV2[]
}

export function getAllDailyReportTrafficV2(
  date: string
): Promise<AxiosResponse<DailyReportTrafficV2Payload>> {
  return instance.get(`/v2/daily-report/traffic`, {
    params: {
      date,
    },
  })
}
