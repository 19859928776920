import { createRouter, createWebHistory, RouteParams } from 'vue-router'
import { useProfile } from '@/composable/useProfile'
import { routes } from './routes'
import { ProjectName } from '@/types/project'
import { useUserSettings } from '@/composable/useUserSettings'
import { socket } from '@/services/socket'

const {
  isSignedIn,
  signOut,
  profile,
  fetchProfile,
  isValidProjectParam,
  updateCurrentProject,
  currentProject,
  can,
} = useProfile()

export type AppRouteNames =
  | 'sign-in'
  | 'events'
  | 'event'
  | 'projects'
  | 'masterdata'
  | 'master-roles'
  | 'master-users'
  | 'master-reasons'
  | 'master-permissions'
  | 'master-vehicle-type-groups'
  | 'master-vehicle-types'
  | 'master-vehicles'
  | 'master-deviation-disruption-reasons'
  | 'master-vehicle-defect-codes'
  | 'master-vehicle-type-group-periods'
  | 'master-vehicle-type-periods'
  | 'daily-report'
  | 'vehicle-deviations'
  | 'cancel-deviations'
  | 'delay-deviations'
  | 'crew-deviations'
  | 'vehicle-withdrawal'
  | 'master-vehicle-withdrawal-depots'
  | 'master-vehicle-withdrawal-reasons'
  | 'master-vehicle-withdrawal-responsibles'
  | 'master-customer-availability-status'
  | 'canceled-tomorrow'
  | 'disruption-deviations'
  | 'daily-report-external'
  | 'deviating-vehicle-composition'
  | 'vehicle-availability'
  | 'deviating-vehicle-with-canceled'
  | 'deviating-missing-distance'

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export function routerPush(
  name: AppRouteNames,
  params?: RouteParams
): ReturnType<typeof router.push> {
  if (params !== undefined) {
    return router.push({
      name,
      params,
    })
  } else {
    return router.push({ name })
  }
}

router.beforeEach(async (to) => {
  const signedIn = await isSignedIn()

  if (!signedIn && to.name !== 'sign-in') signOut()
  if (signedIn && !profile.value) {
    await fetchProfile()
  }

  if (to.params.project && typeof to.params.project === 'string') {
    if (!isValidProjectParam(to.params.project as ProjectName)) {
      updateCurrentProject(null)
      return 'projects'
    }
    updateCurrentProject(to.params.project)
  }

  if (to.meta?.project && typeof to.meta.project === 'string') {
    updateCurrentProject(to.meta.project)
  }

  if (signedIn && to.name === 'sign-in') return 'projects'

  if (to.name !== 'projects' && to.name !== 'sign-in') {
    const { fetchUserSettings } = useUserSettings()
    await fetchUserSettings()
    socket.init()
  }

  if (
    to.meta?.permission &&
    typeof to.meta.permission === 'string' &&
    !can(to.meta.permission)
  ) {
    return '/access-denied'
  }

  document.title =
    (currentProject?.value?.description || 'SJ') + ' | ' + to?.meta?.header ||
    ''
})
