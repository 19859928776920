import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { createDeviationCancel } from '@/api/deviation-cancel/createDeviationCancel'
import { DeviationCancel } from '@/types/deviation-cancel'
import { updateDeviationCancel } from '@/api/deviation-cancel/updateDeviationCancel'
import { useEvent } from '@/composable/useEvent'
import { deleteDeviationCancel } from '@/api/deviation-cancel/deleteDeviationCancel'
import { addDays, format, sub } from 'date-fns'
import { getDeviationCancels } from '@/api/deviation-cancel/getDeviationCancels'

interface UseDeviationCancel {
  data: Ref<DeviationCancel[]>
  fetchAll: (params: FetchAllParams) => Promise<DeviationCancel[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Partial<DeviationCancel>) => Promise<DeviationCancel>
  update: (body: Partial<DeviationCancel>) => Promise<DeviationCancel>
  loading: ComputedRef<Loading>
  period: Ref<Period>
  setDefaultPeriod: () => void
}

interface FetchAllParams {
  from: string
  to: string
}

interface Period {
  from: string
  to: string
}

function createDefaultPeriod() {
  return {
    from: format(sub(new Date(), { days: 5 }), 'yyyy-MM-dd'),
    to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  }
}

const period = ref<Period>(createDefaultPeriod())
const data = ref<DeviationCancel[]>([])
const { state } = useLoading()

export function useDeviationCancel(): UseDeviationCancel {
  const { data: event } = useEvent()

  function setDefaultPeriod() {
    period.value = createDefaultPeriod()
  }

  async function fetchAll(fetchParams: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationCancel[]>((resolve, reject) => {
      getDeviationCancels(fetchParams)
        .then(({ data: deviationCancels }) => {
          if (deviationCancels !== undefined) {
            state.getAll = false
          }
          data.value = deviationCancels || []
          resolve(deviationCancels || [])
        })
        .catch(reject)
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteDeviationCancel(uuid)
        .then(() => {
          if (event.value) {
            event.value.deviationCancels = event.value.deviationCancels.filter(
              (item) => item.uuid !== uuid
            )
          }
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Partial<DeviationCancel>) {
    state.create = true
    return new Promise<DeviationCancel>((resolve, reject) => {
      createDeviationCancel(body)
        .then(({ data: deviationCancel }) => {
          if (event.value) {
            event.value.deviationCancels.push(deviationCancel)
          }
          data.value.push({
            ...deviationCancel,
            eventName: body.eventName || null,
            eventId: body.eventId || null,
            eventIsOpen: body.eventIsOpen || null,
            eventDate: body.eventDate || null,
          })
          resolve(deviationCancel)
        })

        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<DeviationCancel>) {
    state.update = true

    const parsedBody = Object.assign({}, body)
    if (parsedBody.uuid) delete parsedBody.uuid

    return new Promise<DeviationCancel>((resolve, reject) => {
      updateDeviationCancel(parsedBody, body?.uuid || 'missing')
        .then(({ data: updateDeviationCancel }) => {
          if (event.value) {
            event.value.deviationCancels = event.value.deviationCancels.map(
              (deviationCancel) => {
                if (deviationCancel.uuid === body?.uuid) {
                  return updateDeviationCancel
                }

                return deviationCancel
              }
            )
          }

          data.value = data.value.map((deviationCancel) => {
            if (deviationCancel.uuid === body?.uuid) {
              return {
                ...updateDeviationCancel,
                eventName: body.eventName || null,
                eventId: body.eventId || null,
                eventIsOpen: body.eventIsOpen || null,
                eventDate: body.eventDate || null,
              }
            }

            return deviationCancel
          })

          resolve(updateDeviationCancel)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  return {
    data,
    remove,
    add,
    update,
    loading: computed(() => state),
    period,
    setDefaultPeriod,
    fetchAll,
  }
}
