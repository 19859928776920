import { instance } from '@/services/avik-api-gateway'
import { DailyReportVehicleStatus } from '@/types/daily-report-vehicle-status'
import { AxiosResponse } from 'axios'

export function getAllDailyReportVehicleStatus(
  date: string
): Promise<AxiosResponse<DailyReportVehicleStatus[]>> {
  return instance.get(`/daily-report/vehicle-status`, {
    params: {
      date,
    },
  })
}
