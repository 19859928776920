import { instance } from '@/services/avik-api-gateway'
import { DailyReportEvent } from '@/types/daily-report-event'
import { AxiosResponse } from 'axios'

export function getAllDailyReportEvent(
  date: string
): Promise<AxiosResponse<DailyReportEvent[]>> {
  return instance.get(`/daily-report/event`, {
    params: {
      date,
    },
  })
}
