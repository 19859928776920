
import { UseModal } from '@/composable/useModal'
import { useProfile } from '@/composable/useProfile'
import { defineComponent, inject, ref } from 'vue'
import { FILTER_TRACKS_LOCALSTORAGE_KEY } from '@/composable/useDailyReport'
import { useFilterLocalStorage } from '@/composable/useFilterLocalStorage'

export default defineComponent({
  name: 'AppConfirmLogout',

  setup() {
    const modal = inject<UseModal<unknown>>(
      'confirm-logout'
    ) as UseModal<unknown>

    const emptyFilter = ref(false)
    const filterStorage = useFilterLocalStorage()

    const { signOut } = useProfile()

    const onSignOut = () => {
      if (emptyFilter.value) {
        window.localStorage.removeItem(FILTER_TRACKS_LOCALSTORAGE_KEY)
        filterStorage.clear()
      }
      signOut()
    }
    return { modal, onSignOut, emptyFilter }
  },
})
