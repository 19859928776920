import { render } from "./AppNavigation.vue?vue&type=template&id=5fdbc072"
import script from "./AppNavigation.vue?vue&type=script&lang=ts"
export * from "./AppNavigation.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QDialog,QTabs,QRouteTab,QBadge,QBtnDropdown,QList,QItem,QItemSection});
