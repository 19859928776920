
import { validDateRule } from '@/common/formValidationRules'
import isMatch from 'date-fns/isMatch/index'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppFieldDate',

  emits: ['update:modelValue'],

  props: {
    square: {
      type: Boolean,
      default: () => true,
    },
    mask: {
      type: String,
      default: () => '####-##-##',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    modelValue: null,
    label: String,
  },

  setup(props, { emit }) {
    const internalModel = computed({
      get() {
        return props.modelValue
      },
      set(value: string | null) {
        if (
          value === props.modelValue ||
          !value ||
          !isMatch(value, 'yyyy-MM-dd')
        )
          return
        emit('update:modelValue', value)
      },
    })

    return { internalModel, validDateRule }
  },
})
