import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { DeviationCancelProposal } from '@/types/deviation-cancel-proposal'
import { getDeviationCancelProposals } from '@/api/deviation-cancel-proposal/getDeviationCancelProposals'
import { getDeviationCancelProposalAll } from '@/api/deviation-cancel-proposal-all/getDeviationCancelProposalAll'
import { DeviationCancel } from '@/types/deviation-cancel'
import { format } from 'date-fns'
import { useDeviationCancel } from './useDeviationCancel'
import { useStatusBadgeCount } from './useStatusBadgeCount'

type DeviationCancelWithMissingTrv = (DeviationCancel & {
  missingTrv: string | boolean
})[]

interface UseDeviationCancelProposal {
  data: Ref<DeviationCancelProposal[]>
  dataAll: Ref<DeviationCancelProposal[]>
  deviationsWithMissingTrv: ComputedRef<DeviationCancelWithMissingTrv>
  fetchAll: (params: FetchAllParams) => Promise<DeviationCancelProposal[]>
  loading: ComputedRef<Loading>
  loadingGetAll: Ref<boolean>
  fetchCancelProposalsAll: (
    params: FetchAllParams
  ) => Promise<DeviationCancelProposal[]>
}

interface FetchAllParams {
  from: string
  to: string
}

const data = ref<DeviationCancelProposal[]>([])
const dataAll = ref<DeviationCancelProposal[]>([])
const { state } = useLoading()

const loadingGetAll = ref(false)

export function useDeviationCancelProposal(): UseDeviationCancelProposal {
  const { data: statusBadge } = useStatusBadgeCount()
  const { data: deviationCancels } = useDeviationCancel()
  async function fetchAll(params: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationCancelProposal[]>((resolve, reject) => {
      getDeviationCancelProposals(params)
        .then(({ data: deviationCancelProposals }) => {
          if (deviationCancelProposals !== undefined) {
            state.getAll = false
          }
          data.value = deviationCancelProposals || []
          statusBadge.value.activeProposals.deviationCancel = data.value.length
          resolve(deviationCancelProposals || [])
        })
        .catch(reject)
    })
  }

  async function fetchCancelProposalsAll(params: FetchAllParams) {
    loadingGetAll.value = true
    return new Promise<DeviationCancelProposal[]>((resolve, reject) => {
      getDeviationCancelProposalAll(params)
        .then(({ data: deviationCancelProposals }) => {
          if (deviationCancelProposals !== undefined) {
            loadingGetAll.value = false
          }
          dataAll.value = deviationCancelProposals
          resolve(deviationCancelProposals)
        })
        .catch(reject)
    })
  }

  const deviationsWithMissingTrv = computed(() => {
    const deviationCancelsParsed = deviationCancels.value.map((x) => {
      let missingTrv: string | false = false

      const foundProposalAdvertised = dataAll.value.find((p) => {
        return (
          p.advertised === x.advertised &&
          p.departureDate.substring(0, 10) ===
            format(new Date(x.departureDate), 'yyyy-MM-dd')
        )
      })

      if (!foundProposalAdvertised) {
        missingTrv = 'Tåg ej operativt inställt hos Trafikverket'
      }

      if (foundProposalAdvertised) {
        const foundProposalPlaces = dataAll.value.find(
          (p) =>
            p.advertised === x.advertised && p.from === x.from && p.to === x.to
        )

        if (!foundProposalPlaces) {
          missingTrv = `Tåget inställt mellan ${foundProposalAdvertised.from} - ${foundProposalAdvertised.to}`
        }
      }

      if (x.advertised === 0) {
        missingTrv = false
      }

      if (
        typeof x.advertised === 'number' &&
        x.advertised >= 300 &&
        x.advertised <= 399
      ) {
        missingTrv = false
      }

      return {
        ...x,
        missingTrv,
      }
    })

    return deviationCancelsParsed
  })

  return {
    data,
    dataAll,
    fetchAll,
    fetchCancelProposalsAll,
    loading: computed(() => state),
    loadingGetAll,
    deviationsWithMissingTrv,
  }
}
