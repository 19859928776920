import { io, Socket } from 'socket.io-client'
import { useProfile } from '@/composable/useProfile'

const SOCKET = `wss://sj-avik-backend.reges-services.se`

class SocketManager {
  instance: null | Socket = null
  inited = false

  init() {
    if (this.inited) return
    this.inited = true
    if (this.instance) return

    const { getToken } = useProfile()
    this.instance = io(SOCKET, {
      transports: ['websocket'],
      reconnectionDelayMax: 10000,
      auth: {
        token: getToken(),
      },
    })
  }
}

export const socket = new SocketManager()
