import { instance } from '@/services/avik-api-gateway'
import { DailyReportTraffic } from '@/types/daily-report-traffic'
import { AxiosResponse } from 'axios'

export function getAllDailyReportTraffic(
  date: string
): Promise<AxiosResponse<DailyReportTraffic[]>> {
  return instance.get(`/daily-report/traffic`, {
    params: {
      date,
    },
  })
}
