import { instance } from '@/services/avik-api-gateway'
import { PenaltyAssessment } from '@/types/penalty-assessment'
import { AxiosResponse } from 'axios'

export function updateEventPenaltyAssessment(
  uuid: string,
  body: Omit<PenaltyAssessment, 'uuid' | 'eventUuid' | 'logs'>
): Promise<AxiosResponse<PenaltyAssessment>> {
  return instance.put(`/event-penalty-assessment/${uuid}`, body)
}
