<template>
  <q-input
    :square="square"
    outlined
    :label="label"
    v-model="internalModel"
    :rules="[...rules, validDateRule]"
    :mask="mask"
  >
    <template v-slot:append>
      <q-icon name="mdi-calendar-range" class="cursor-pointer">
        <q-popup-proxy
          ref="qDateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date mask="YYYY-MM-DD" v-model="internalModel">
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Stäng" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script lang="ts">
import { validDateRule } from '@/common/formValidationRules'
import isMatch from 'date-fns/isMatch/index'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppFieldDate',

  emits: ['update:modelValue'],

  props: {
    square: {
      type: Boolean,
      default: () => true,
    },
    mask: {
      type: String,
      default: () => '####-##-##',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    modelValue: null,
    label: String,
  },

  setup(props, { emit }) {
    const internalModel = computed({
      get() {
        return props.modelValue
      },
      set(value: string | null) {
        if (
          value === props.modelValue ||
          !value ||
          !isMatch(value, 'yyyy-MM-dd')
        )
          return
        emit('update:modelValue', value)
      },
    })

    return { internalModel, validDateRule }
  },
})
</script>
