import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'

export interface DailyReportEventSection {
  banor: string[]
  description: string
  eventUuid: string
  id: number
  trvIds: string[]
}

export function getEventSection(
  date: string
): Promise<AxiosResponse<DailyReportEventSection[]>> {
  return instance.get(`/v2/daily-report/event-selection`, {
    params: {
      date,
    },
  })
}
