
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import { defineComponent } from 'vue'
import { useProfile } from '@/composable/useProfile'
import { routerPush } from '@/router'

import AppLogo from '@/components/AppLogo.vue'

export default defineComponent({
  name: 'AppTopBar',

  components: {
    AppLogo,
    AppAccountDropdown,
  },

  setup() {
    const { currentProject } = useProfile()

    return { currentProject, routerPush }
  },
})
