
import { defineComponent, inject, ref } from 'vue'
import {
  minCharactersRule,
  required as requiredRule,
} from '@/common/formValidationRules'
import QForm from 'quasar/src/components/form/QForm.js';
import { useProfile } from '@/composable/useProfile'
import { UseModal } from '@/composable/useModal'

export default defineComponent({
  name: 'AppChangePasswordModal',

  setup() {
    const success = ref(false)
    const showError = ref(false)
    const { updatePassword: firebaseChangePassword, pendingChangePassword } =
      useProfile()
    const formRef = ref<QForm | null>(null)
    const changePasswordModal = inject<UseModal<unknown>>(
      'change-password-modal'
    )
    const model = ref({
      oldPassword: '',
      newPassword: '',
    })
    const showPasswordOld = ref(false)
    const showPasswordNew = ref(false)

    async function updatePassword() {
      showError.value = false
      const isValid = await formRef.value?.validate()
      if (!isValid) return
      const data = await firebaseChangePassword(model.value)
      if (data === 'no user') {
        showError.value = true
      } else if (data === 'ok') {
        success.value = true
      } else {
        changePasswordModal?.closeModal()
      }
    }
    return {
      changePasswordModal,
      model,
      showPasswordOld,
      showPasswordNew,
      minCharactersRule,
      requiredRule,
      updatePassword,
      formRef,
      pendingChangePassword,
      showError,
      success,
    }
  },
})
