import { Ref, ref, ComputedRef, computed } from 'vue'
import { Event, EventTableItem } from '@/types/event'
import { getEvents } from '@/api/event/getEvents'
import { useLoading, Loading } from '@/composable/useLoading'
import { sub, format, add } from 'date-fns'
import orderBy from 'lodash.orderby'
import { createEvent } from '@/api/event/createEvent'
import { getEvent } from '@/api/event/getEvent'
import { updateEvent } from '@/api/event/updateEvent'
import { deleteEvent } from '@/api/event/deleteEvent'
import { closeEvent, CloseEventBody } from '@/api/closed-event/closeEvent'
import { openEvent } from '@/api/event/openEvent'
import { Log } from '@/types/log'
import { getLog } from '@/api/log/getLog'
import { useStatusBadgeCount } from '@/composable/useStatusBadgeCount'
import { PenaltyAssessment } from '@/types/penalty-assessment'
import { createEventPenaltyAssessment } from '@/api/event-penalty-assessment/createEventPenaltyAssessment'
import { updateEventPenaltyAssessment } from '@/api/event-penalty-assessment/updateEventPenaltyAssessment'
import { getEventPenaltyAssessment } from '@/api/event-penalty-assessment/getEventPenaltyAssessment'
import { deleteEventPenaltyAssessment } from '@/api/event-penalty-assessment/deleteEventPenaltyAssessment'
import {
  DailyReportEventSection,
  getEventSection,
} from '@/api/daily-report/getEventSection'

interface UseEvent {
  list: Ref<EventTableItem[]>
  data: Ref<Event | null>
  close: (body: CloseEventBody) => Promise<null>
  open: (eventUuid: string) => Promise<null>
  createPenaltyAssessment: (body: Partial<PenaltyAssessment>) => Promise<null>
  updatePenaltyAssessment: (
    uuid: string,
    eventUuid: string,
    body: Omit<PenaltyAssessment, 'uuid' | 'eventUuid' | 'logs'>
  ) => Promise<null>
  getPenaltyAssessment: (eventUuid: string) => Promise<PenaltyAssessment | null>
  deletePenaltyAssessment: (uuid: string, eventUuid: string) => Promise<null>
  remove: (uuid: string) => Promise<null>
  fetch: (eventUuid: string) => Promise<Event>
  fetchLog: (eventUuid: string) => Promise<Log[]>
  fetchAll: (fetchParams: EventPeriod) => Promise<EventTableItem[]>
  add: (body: Partial<Event>) => Promise<Event>
  update: (body: Partial<Event>) => Promise<Event>
  loading: ComputedRef<Loading>
  period: Ref<EventPeriod>
  setDefaultPeriod: () => void
  log: Ref<Log[]>
  pagination: Ref<Pagination>
  penaltyAssessment: Ref<PenaltyAssessment | null>
  filterPenaltyAssessment: Ref<boolean>
  filterAutoDelay: Ref<boolean>
  filterText: Ref<string>
  filterDeleted: Ref<boolean>
  uniqueRoles: Ref<{ label: string; visible: boolean }[]>
  listDailyReportEventSection: Ref<DailyReportEventSection[]>
  fetchDailyReportEventSection: (
    fetchParams: EventPeriod
  ) => Promise<DailyReportEventSection[]>
}

interface EventPeriod {
  from: string
  to: string
}

interface Pagination {
  sortBy: string
  descending: boolean
}

function createDefaultPeriod() {
  return {
    from: format(sub(new Date(), { days: 2 }), 'yyyy-MM-dd'),
    to: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
  }
}

const { state } = useLoading()
const data = ref<Event | null>(null)
const list = ref<EventTableItem[]>([])
const listDailyReportEventSection = ref<DailyReportEventSection[]>([])
const period = ref<EventPeriod>(createDefaultPeriod())
const log = ref<Log[]>([])
const pagination = ref({
  sortBy: 'open',
  descending: true,
})
const filterPenaltyAssessment = ref(false)
const filterAutoDelay = ref(false)
const filterDeleted = ref(false)
const penaltyAssessment = ref<PenaltyAssessment | null>(null)
const filterText = ref('')

const uniqueRoles = ref<{ label: string; visible: boolean }[]>([
  {
    label: 'Fordonsledare SJG',
    visible: true,
  },
  {
    label: 'Trafiksamordnare SJG',
    visible: true,
  },
])

export function useEvent(initFetchAll?: boolean): UseEvent {
  const { data: statusBadge } = useStatusBadgeCount()
  async function fetchLog(eventUuid: string) {
    return new Promise<Log[]>((resolve, reject) => {
      getLog(eventUuid)
        .then(({ data: eventLog }) => {
          log.value = eventLog
          resolve(eventLog)
        })
        .catch(reject)
    })
  }

  async function fetchAll(fetchParams: EventPeriod) {
    state.getAll = true
    return new Promise<EventTableItem[]>((resolve, reject) => {
      getEvents(fetchParams || period)
        .then(({ data: events }) => {
          list.value = events
          // uniqueRoles.value = [
          //   ...new Set(events.flatMap((x) => x.createdByRoles)),
          // ].map((x) => ({
          //   label: x,
          //   visible: true,
          // }))
          statusBadge.value.activeEvents = events.filter((x) => x.open).length
          resolve(events)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function fetchDailyReportEventSection(fetchParams: EventPeriod) {
    state.getAll = true
    return new Promise<DailyReportEventSection[]>((resolve, reject) => {
      getEventSection(fetchParams.from || period.value.from)
        .then(({ data: events }) => {
          listDailyReportEventSection.value = events
          resolve(events)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function fetch(eventUuid: string) {
    state.get = true
    return new Promise<Event>((resolve, reject) => {
      getEvent(eventUuid)
        .then(({ data: event }) => {
          data.value = event
          resolve(event)
        })
        .catch(reject)
        .finally(() => {
          state.get = false
        })
    })
  }

  async function add(body: Partial<Event>) {
    state.create = true
    return new Promise<Event>((resolve, reject) => {
      createEvent(body)
        .then(({ data: event }) => {
          resolve(event)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<Event>) {
    state.update = true
    return new Promise<Event>((resolve, reject) => {
      updateEvent(body)
        .then(({ data: event }) => {
          data.value = event
          resolve(event)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteEvent(uuid)
        .then(() => {
          list.value = list.value.filter((item) => item.uuid !== uuid)
          statusBadge.value.activeEvents = list.value.filter(
            (x) => x.open
          ).length
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function close(body: CloseEventBody) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      closeEvent(body)
        .then(() => {
          list.value = list.value.map((item) => {
            if (item.uuid === body.eventUuid) {
              return {
                ...item,
                end: new Date(body.closedAt),
                open: false,
              }
            }

            return item
          })

          if (data.value) {
            data.value.end = new Date(body.closedAt)
          }
          statusBadge.value.activeEvents = list.value.filter(
            (x) => x.open
          ).length
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function open(eventUuid: string) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      openEvent(eventUuid)
        .then(() => {
          list.value = list.value.map((item) => {
            if (item.uuid === eventUuid) {
              return {
                ...item,
                end: null,
                open: true,
              }
            }

            return item
          })

          if (data.value) {
            data.value.end = null
          }
          statusBadge.value.activeEvents = list.value.filter(
            (x) => x.open
          ).length
          resolve(null)
        })

        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function createPenaltyAssessment(body: Partial<PenaltyAssessment>) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      createEventPenaltyAssessment(body)
        .then((res) => {
          list.value = list.value.map((item) => {
            if (item.uuid === body.eventUuid) {
              return {
                ...item,
                penaltyAssessment:
                  typeof body.penalty === 'boolean' ? body.penalty : null,
              }
            }

            return item
          })

          if (data.value) {
            data.value.penaltyAssessment = res.data
          }

          resolve(null)
        })

        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function updatePenaltyAssessment(
    uuid: string,
    eventUuid: string,
    body: Omit<PenaltyAssessment, 'uuid' | 'eventUuid' | 'logs'>
  ) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      updateEventPenaltyAssessment(uuid, body)
        .then((res) => {
          list.value = list.value.map((item) => {
            if (item.uuid === eventUuid) {
              return {
                ...item,
                penaltyAssessment: body.penalty,
              }
            }

            return item
          })

          if (data.value) {
            data.value.penaltyAssessment = res.data
          }

          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function getPenaltyAssessment(eventUuid: string) {
    return getEventPenaltyAssessment(eventUuid)
      .then(({ data }) => {
        if (data) {
          penaltyAssessment.value = data
        } else {
          penaltyAssessment.value = null
        }

        return data
      })
      .catch(() => {
        penaltyAssessment.value = null
        return null
      })
  }

  async function deletePenaltyAssessment(uuid: string, eventUuid: string) {
    state.update = true
    return new Promise<null>((resolve, reject) => {
      deleteEventPenaltyAssessment(uuid)
        .then(() => {
          list.value = list.value.map((item) => {
            if (item.uuid === eventUuid) {
              return {
                ...item,
                penaltyAssessment: null,
              }
            }

            return item
          })

          if (data.value) {
            data.value.penaltyAssessment = null
          }

          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll(period.value)
  }

  function setDefaultPeriod() {
    period.value = createDefaultPeriod()
  }

  return {
    data: computed(() => data.value),
    list: computed(() => orderBy(list.value, ['date'], ['desc'])),
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
    fetch,
    period,
    close,
    setDefaultPeriod,
    fetchLog,
    log,
    open,
    pagination,
    createPenaltyAssessment,
    updatePenaltyAssessment,
    getPenaltyAssessment,
    penaltyAssessment,
    deletePenaltyAssessment,
    filterPenaltyAssessment,
    filterAutoDelay,
    filterText,
    filterDeleted,
    uniqueRoles,
    fetchDailyReportEventSection,
    listDailyReportEventSection,
  }
}
