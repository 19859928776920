
import { computed, defineComponent } from 'vue'
import { useStatusbar } from '@/composable/useStatusbar'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'AppStatusbar',

  setup() {
    const { currentProject } = useProfile()
    const { data, showBanner, closeBanner } = useStatusbar(
      ['gotalandstag', 'krosatag'].includes(currentProject.value?.name || '')
    )

    const showData = computed(() => {
      return showBanner.value && data.value.length > 0
    })

    return {
      data,

      closeBanner,
      showData,
    }
  },
})
