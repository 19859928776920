
import { defineComponent, ref, computed, ComputedRef } from 'vue'
import { useRoute } from 'vue-router'
import { routerPush } from '@/router'
import { useProfile } from '@/composable/useProfile'
import { useModal } from '@/composable/useModal'
import ExportRawListModal from '@/components/tools/export-raw-list/ExportRawListModal.vue'
import ExportDeviationListModal from '@/components/tools/export-deviation-list/ExportDeviationListModal.vue'
import { useStatusBadgeCount } from '@/composable/useStatusBadgeCount'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useApp } from '@/composable/useApp'

interface NavigationTab {
  label: string
  to?: string
  badge?: number
}

export default defineComponent({
  name: 'AppNavigation',

  components: {
    ExportRawListModal,
    ExportDeviationListModal,
  },

  setup() {
    const route = useRoute()
    const { currentProject } = useProfile()
    const { inited } = useApp()
    const activeNavigationTab = ref(route.name)
    const { can } = useProfile()
    const {
      fetchActiveEvents,
      total,
      data: statusBadge,
      fetchActiveProposals,
    } = useStatusBadgeCount()
    const { period, fetchAll: fetchAllDeviationCancel } = useDeviationCancel()

    const { deviationsWithMissingTrv, fetchCancelProposalsAll } =
      useDeviationCancelProposal()

    if (
      !inited.value &&
      can('deviationManagement.event.list') &&
      can('deviationManagement.deviationCancel.list') &&
      can('deviationManagement.deviationDelay.list') &&
      can('deviationManagement.deviationVehicle.list') &&
      can('deviationManagement.deviationCrew.list')
    ) {
      fetchAllDeviationCancel(period.value)
      fetchCancelProposalsAll(period.value)
      fetchActiveEvents()
      fetchActiveProposals()
      inited.value = true
    }

    const exportRawListModal = useModal()
    const exportDeviationListModal = useModal()
    const project = currentProject.value?.name as string
    const navigationTabs: ComputedRef<NavigationTab[]> = computed(() => {
      return [
        {
          key: 'events',
          label: 'Händelser',
          to: `/${route.params.project}/event`,
          badge: statusBadge.value.activeEvents,
          badgeColor: 'primary',
          visible: can('ui.navigation.event'),
        },
        {
          key: 'deviations',
          label: 'Avvikelser',
          badge: total.value,
          badgeColor: 'secondary',
          visible: [
            'ui.navigation.deviation.vehicle',
            'ui.navigation.deviation.cancel',
            'ui.navigation.deviation.delay',
            'ui.navigation.deviation.crew',
            'ui.navigation.deviation.disruption',
          ].some(can),
          children: [
            {
              key: 'vehicle-deviations',
              label: 'Fordonsavvikelser',
              badge: statusBadge.value.activeProposals.deviationVehicle,
              permission: 'ui.navigation.deviation.vehicle',
              badgeColor: 'secondary',
            },
            {
              key: 'cancel-deviations',
              label: 'Inställda tåg',
              permission: 'ui.navigation.deviation.cancel',
              badges: [
                {
                  badge: deviationsWithMissingTrv.value.filter(
                    (x) => x.missingTrv
                  ).length,
                  badgeColor: 'negative',
                },
                {
                  badge: statusBadge.value.activeProposals.deviationCancel,
                  badgeColor: 'secondary',
                },
              ].filter((x) => x.badge),
            },
            {
              key: 'delay-deviations',
              label: 'Förseningar',
              badge: statusBadge.value.activeProposals.deviationDelay,
              permission: 'ui.navigation.deviation.delay',
              badgeColor: 'secondary',
            },
            {
              key: 'crew-deviations',
              label: 'Bemanning',
              permission: 'ui.navigation.deviation.crew',
            },
            {
              key: 'disruption-deviations',
              label: 'Störningshantering',
              permission: 'ui.navigation.deviation.disruption',
            },
          ]
            .filter(({ permission }) => can(permission))
            .filter((x) => {
              if (x.key === 'disruption-deviations') {
                return project !== 'gotalandstag'
              }
              return true
            }),
        },
        {
          key: 'daily-report',
          label: 'Dygnsrapport intern',
          to: `/${route.params.project}/daily-report`,
          visible: can('ui.navigation.dailyReport'),
        },
        {
          key: 'daily-report-preview',
          label: 'Dygnsrapport intern - preview',
          to: `/${route.params.project}/daily-report-preview`,
          visible: can('report.dailyReportInternal.preview'),
        },
        {
          key: 'daily-report-external',
          label: 'Dygnsrapport gammal',
          to: `/${route.params.project}/daily-report-external`,
          visible: can('ui.navigation.dailyReportExternal'),
        },
        {
          key: 'daily-report-external-v2',
          label: 'Dygnsrapport',
          to: `/${route.params.project}/daily-report-external-v2`,
          visible: can('ui.navigation.dailyReportExternalV2'),
        },
        {
          key: 'vehicle-availability',
          label: 'Fordonstillgänglighet',
          to: `/${route.params.project}/vehicle-availability`,
          visible: can('ui.navigation.withdrawalDashboard'),
        },
        {
          key: 'vehicle-withdrawal',
          label: 'Avställningar',
          to: `/${route.params.project}/vehicle-withdrawal`,
          visible: can('ui.navigation.vehicleWithdrawal'),
        },
        {
          key: 'tools',
          label: 'Verktyg',
          visible: [
            'ui.navigation.tools.raw',
            'ui.navigation.plannedCancellation',
            'ui.navigation.tools.deviation',
          ].some(can),
          children: [
            {
              key: 'export-raw-list',
              label: 'Exportera rålista',
              permission: 'ui.navigation.tools.raw',
              modal: () => {
                exportRawListModal.openModal()
              },
            },
            {
              key: 'export-deviation-list',
              label: 'Exportera avvikelser',
              permission: 'ui.navigation.tools.deviation',
              modal: () => {
                exportDeviationListModal.openModal()
              },
            },
            {
              key: 'canceled-tomorrow',
              label: 'Planerat inställda tåg',
              to: `/${route.params.project}/canceled-tomorrow`,
              permission: 'ui.navigation.tools.plannedCancellation',
            },
            {
              key: 'vehicle-defects',
              label: 'Öppna fordonsskador',
              to: `/${route.params.project}/vehicle-defects`,
              permission: 'ui.navigation.tools.vehicleDefects',
            },
            {
              key: 'operational-defects',
              label: 'Driftstörande fel',
              to: `/${route.params.project}/operational-defects`,
              permission: 'ui.navigation.tools.operationalDefects',
            },
            {
              key: 'deviating-vehicle-composition',
              label: 'Avvikande Fordonssammansättning',
              to: `/${route.params.project}/deviating-vehicle-composition`,
              permission: 'ui.navigation.tools.deviatingVehicleComposition',
            },
            {
              key: 'deviating-vehicle-with-canceled',
              label: 'Fordonsavvikelser med inställda tåg',
              to: `/${route.params.project}/deviating-vehicle-with-canceled`,
              permission: 'ui.navigation.tools.deviatingVehicleWithCanceled',
            },
            {
              key: 'deviating-missing-distance',
              label: 'Avvikelser som saknar distans',
              to: `/${route.params.project}/deviating-missing-distance`,
              permission: 'deviatingMissingDistance',
            },
          ].filter(({ permission }) => (permission ? can(permission) : true)),
        },
        {
          key: 'graph',
          label: 'Graf',
          to: `/${route.params.project}/graph`,
          visible: can('ui.navigation.graph'),
        },
        {
          key: 'masterdata',
          label: 'Admin',
          to: `/${route.params.project}/masterdata`,
          visible: can('ui.navigation.masterdata'),
        },
      ].filter(({ visible }) => visible)
    })

    return {
      activeNavigationTab,
      navigationTabs,
      routerPush,
      exportRawListModal,
      exportDeviationListModal,
    }
  },
})
