import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/mdi-v4.js'
import lang from 'quasar/lang/sv.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog,
  },
  lang: lang,
  iconSet: iconSet,
}
