import { UnwrapRef, reactive } from 'vue'
import Loading from 'quasar/src/plugins/Loading.js';

interface Params<T> {
  mode?: string | null
  data?: UnwrapRef<T | null>
  cb?: (setData: (data: UnwrapRef<T | null>) => void) => Promise<void>
}

export interface UseModal<T = void> {
  state: UnwrapRef<Modal<T>>
  openModal: (params?: Params<T>) => void
  closeModal: () => void
}

export interface Modal<T> {
  mode: string | null
  show: boolean
  data: T | null
}

export function useModal<T>(): UseModal<T> {
  const state = reactive<Modal<T>>({
    mode: null,
    show: false,
    data: null,
  })

  function setData(data: UnwrapRef<T | null>) {
    state.data = data
  }

  async function openModal(params?: Params<T>) {
    if (params?.cb) {
      Loading.show()
      await params.cb(setData).catch(() => {
        Loading.hide()
      })
      Loading.hide()
    }
    if (params?.data) {
      state.data = params?.data || null
    }
    state.show = true
    state.mode = params?.mode || null
  }

  function closeModal() {
    state.show = false
    state.data = null
    state.mode = null
  }

  return { state, openModal, closeModal }
}
