import firebase from 'firebase/app'
import 'firebase/auth'
import { CONFIG } from '@/config'
import { getProfile } from '@/api/profile/getProfile'
import { Ref, ref, ComputedRef, computed } from 'vue'
import { routerPush } from '@/router'
import { Profile } from '@/types/profile'
import { Project, ProjectName } from '@/types/project'
import { useError } from '@/composable/useError'
const { applicationErrorHandler } = useError()

type SamlProvider = 'saml.reges' | 'saml.sj-avik'

type ChangePassword = {
  oldPassword: string
  newPassword: string
}

export const signInSSO = (samlProvider: SamlProvider): void => {
  const provider = new firebase.auth.SAMLAuthProvider(samlProvider)

  firebase
    .auth()
    .signInWithPopup(provider)
    .then(() => {
      routerPush('projects')
    })
    .catch(applicationErrorHandler)
}

interface UseProfile {
  profile: Ref<Profile | null>
  signInPending: Ref<boolean>
  signOutPending: Ref<boolean>
  pendingChangePassword: Ref<boolean>
  pendingResetPassword: Ref<boolean>
  signIn: (userCredentials: UserCredentials) => void
  signOut: () => Promise<null>
  getToken: () => string | null
  isSignedIn: () => Promise<boolean>
  fetchProfile: () => Promise<Profile>
  projects: ComputedRef<Project[]>
  email: ComputedRef<string | null>
  isValidProjectParam: (projectName: ProjectName) => boolean
  currentProject: Ref<Project | null>
  updateCurrentProject: (project: string | null) => void
  getCurrentProject: () => Project | null
  can: (permission: string) => boolean
  projectDependentInputFields: ComputedRef<InputFields>
  updatePassword: (changePassword: ChangePassword) => Promise<string>
  resetPassword: (email: string) => Promise<string>
}

interface UserCredentials {
  email: string
  password: string
}

interface InputFields {
  vehicleType?: boolean
  vehicleChange?: boolean
  ford?: boolean
  errorRemedy?: boolean
  mims?: boolean
  crewType?: boolean
  crewChange?: boolean
  crewCount?: boolean
}

interface ProjectDependentInputFields {
  [project: string]: InputFields
}

const config = {
  apiKey: CONFIG.FIREBASE_API_KEY,
  authDomain: CONFIG.FIREBASE_AUTH_DOMAIN,
}

const app = firebase.initializeApp(config)

const profile = ref<Profile | null>(null)
const signInPending = ref(false)
const signOutPending = ref(false)
const pendingChangePassword = ref(false)
const pendingResetPassword = ref(false)
const currentProject = ref<Project | null>(null)

const projects = computed<Project[]>(() => {
  if (!profile.value) return []
  return profile.value.projects
})

const email = computed(() => {
  if (!profile.value) return null
  return profile.value.email
})

const PROJECT_DEPENDENT_INPUTFIELDS: ProjectDependentInputFields = {
  krosatag: {
    vehicleType: true,
    ford: true,
    errorRemedy: true,
    crewCount: true,
  },
  gotalandstag: {
    vehicleType: true,
    ford: true,
    errorRemedy: true,
    crewCount: true,
  },
  oresund: {
    vehicleChange: true,
    mims: true,
    crewType: true,
    crewChange: true,
  },
  test: {
    vehicleType: true,
    ford: true,
    errorRemedy: true,
    crewCount: true,
  },
}

const projectDependentInputFields = computed(() => {
  if (!currentProject.value) return {}
  return PROJECT_DEPENDENT_INPUTFIELDS[currentProject.value.name]
})

export function useProfile(): UseProfile {
  function updateToken(token: string | null) {
    if (!token) {
      return window.localStorage.removeItem(CONFIG.TOKEN_NAME)
    }
    window.localStorage.setItem(CONFIG.TOKEN_NAME, token)
  }

  function getToken() {
    return window.localStorage.getItem(CONFIG.TOKEN_NAME)
  }

  async function fetchProfile() {
    return new Promise<Profile>((resolve, reject) => {
      getProfile()
        .then(async ({ data }) => {
          updateProfile(data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  async function isSignedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      app.auth().onAuthStateChanged((user) => {
        if (!user) {
          updateToken(null)
          resolve(false)
        } else {
          user
            .getIdTokenResult()
            .then(({ token }) => {
              updateToken(token)
              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        }
      })
    })
  }

  function isValidProjectParam(projectName: ProjectName) {
    return projects.value.map((project) => project.name).includes(projectName)
  }

  async function signIn({ email, password }: UserCredentials) {
    signInPending.value = true

    return new Promise((resolve, reject) => {
      app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
          if (!user) {
            throw new Error('No user found')
          }
          return user.getIdTokenResult().then(({ token }) => token)
        })
        .then(updateToken)
        .then(resolve)
        .catch(reject)
        .finally(() => {
          signInPending.value = false
        })
    })
  }

  async function signOut() {
    return new Promise<null>((resolve, reject) => {
      app
        .auth()
        .signOut()
        .then(() => {
          updateToken(null)
          updateProfile(null)
          routerPush('sign-in')
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          signInPending.value = false
        })
    })
  }

  async function updatePassword({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string
    newPassword: string
  }) {
    pendingChangePassword.value = true
    return new Promise<string>((resolve, reject) => {
      if (!email.value) return resolve('no user')
      signIn({ email: email.value, password: oldPassword })
        .then(() => {
          const user = firebase.auth().currentUser
          if (!user) return resolve('no user')
          user
            .updatePassword(newPassword)
            .then(() => {
              resolve('ok')
            })
            .catch(reject)
        })
        .catch((error) => {
          console.log(error)
          return resolve('no user')
        })
        .finally(() => {
          pendingChangePassword.value = false
        })
    })
  }

  function resetPassword(email: string) {
    pendingResetPassword.value = true
    return app
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return 'ok'
      })
      .catch((error) => {
        return error.code
      })
      .finally(() => {
        pendingResetPassword.value = false
      })
  }

  function updateCurrentProject(projectName: string | null) {
    const project = projects.value.find(
      (project) => project.name === projectName
    )
    if (project) {
      currentProject.value = project
    }
  }

  function getCurrentProject() {
    return currentProject.value
  }

  function updateProfile(profileData: Profile | null): void {
    profile.value = profileData
  }

  function can(permission: string) {
    if (!profile.value || !currentProject.value) return false

    return profile.value.permissions[currentProject.value.name].includes(
      permission
    )
  }

  return {
    projectDependentInputFields,
    can,
    signOut,
    profile,
    signInPending,
    signOutPending,
    signIn,
    getToken,
    isSignedIn,
    fetchProfile,
    projects,
    isValidProjectParam,
    email,
    currentProject,
    updateCurrentProject,
    getCurrentProject,
    updatePassword,
    pendingChangePassword,
    resetPassword,
    pendingResetPassword,
  }
}
