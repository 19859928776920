import { render } from "./App.vue?vue&type=template&id=60183d76"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "./App.vue?vue&type=style&index=0&id=60183d76&lang=scss"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable});
