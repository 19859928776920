
import {
  validDateRule,
  required as requiredRule,
} from '@/common/formValidationRules'
import QForm from 'quasar/src/components/form/QForm.js';
import { computed, defineComponent, ref } from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { format, addDays, isAfter, isEqual } from 'date-fns'
import { exportExcel } from '@/services/export-excel'
import { exportEventRaw, ExportEventParamsRaw } from '@/api/export/exportEvent'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'ExportRawListModal',

  components: {
    AppFieldDate,
  },

  emits: ['close'],

  setup(props, { emit }) {
    const model = ref<ExportEventParamsRaw>({
      from: format(new Date(), 'yyyy-MM-dd'),
      to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    })
    const { currentProject } = useProfile()
    const exporting = ref(false)

    const formRef = ref<QForm | null>(null)

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      exporting.value = true

      const { data } = await exportEventRaw(model.value)

      const fileName = [
        'Rålista',
        currentProject?.value?.name || '',
        model.value.from,
        model.value.to,
      ].join(' ')
      const exportData = {
        name: fileName,
        sheets: [
          {
            name: 'Inställt tåg',
            data: data?.deviationCancel || [],
          },
          {
            name: 'Fordonsavvikelse',
            data: data?.deviationVehicle || [],
          },
          {
            name: 'Försening',
            data: data?.deviationCrew || [],
          },
          {
            name: 'Bemanning',
            data: data?.deviationDelay || [],
          },
        ],
      }

      exportExcel(exportData)

      exporting.value = false

      emit('close')
    }

    const validDate = computed(() => {
      return (
        isAfter(new Date(model.value.to), new Date(model.value.from)) ||
        isEqual(new Date(model.value.to), new Date(model.value.from))
      )
    })
    return {
      exporting,
      onSubmit,
      formRef,
      validDateRule,
      requiredRule,
      model,
      validDate,
    }
  },
})
