import { Ref, ref, ComputedRef, computed } from 'vue'

import { useLoading, Loading } from '@/composable/useLoading'
import { Statusbar } from '@/types/statusbar'
import { getStatusbar } from '@/api/statusbar/getStatusbar'
import { createStatusbar } from '@/api/statusbar/createStatusbar'
import { updateStatusbar } from '@/api/statusbar/updateStatusbar'
import { deleteStatusbar } from '@/api/statusbar/deleteStatusbar'

interface useStatusbar {
  data: Ref<Statusbar[]>
  fetchAll: () => Promise<Statusbar>
  add: (body: Statusbar) => Promise<Statusbar>
  remove: (uuid: string) => Promise<null>
  update: (body: Statusbar) => Promise<Statusbar>
  loading: ComputedRef<Loading>
  showBanner: Ref<boolean>
  closeBanner: () => void
}

const data = ref<Statusbar[]>([])
const showBanner = ref<boolean>(true)
export function useStatusbar(initFetchAll?: boolean): useStatusbar {
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<Statusbar>((resolve, reject) => {
      getStatusbar()
        .then(({ data: statusbars }) => {
          if (statusbars) {
            data.value = [statusbars]
          }
          resolve(statusbars)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function add(body: Statusbar) {
    state.create = true
    return new Promise<Statusbar>((resolve, reject) => {
      createStatusbar(body)
        .then(({ data: status }) => {
          data.value.push(status)
          resolve(status)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Statusbar) {
    state.update = true
    return new Promise<Statusbar>((resolve, reject) => {
      updateStatusbar(body)
        .then(({ data: statusbar }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === statusbar.uuid) {
              return {
                ...item,
                ...statusbar,
              }
            }

            return item
          })
          resolve(statusbar)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteStatusbar(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  const closeBanner = () => {
    showBanner.value = false
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    remove,
    update,
    showBanner,
    loading: computed(() => state),
    closeBanner,
  }
}
