<template>
  <div>
    <div id="print"></div>
    <AppErrorModal />
    <RouterView v-slot="{ Component, route }">
      <transition
        enter-active-class="animated animate__fadeIn"
        leave-active-class="animated animate__fadeOut"
        mode="out-in"
        :duration="140"
      >
        <component :is="Component" :key="route.fullPath" />
      </transition>
    </RouterView>
  </div>
</template>

<script lang="ts">
import AppErrorModal from '@/components/AppErrorModal.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',

  components: {
    AppErrorModal,
  },
})
</script>

<style lang="scss">
.printMode .hideOnPrint {
  display: none !important;
}

table tr td,
table tr div {
  page-break-inside: avoid !important;
}

.printMode .q-table__container {
  display: block !important;
}

.printMode * {
  overflow: hidden !important;
}

.printMode .q-table__container th div {
  font-size: 8px !important;
}

.ganttScroll {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
}
</style>
