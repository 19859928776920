import { render } from "./AppTopBar.vue?vue&type=template&id=3ec39157"
import script from "./AppTopBar.vue?vue&type=script&lang=ts"
export * from "./AppTopBar.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QToolbarTitle,QBadge,QSpace});
