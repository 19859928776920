import { useProfile } from './useProfile'

interface FilterLocalStoragePath {
  project: string
  view: string
}

interface Track {
  label: string
  visible: boolean
}

interface Filter {
  tracksInView: Track[]
  selectedEventTypes: string[] | null
}

const defaultFilter = (): Filter => ({
  tracksInView: [],
  selectedEventTypes: null,
})

interface UseFilterLocalStorage {
  setFilterLocalstorage: (
    path: FilterLocalStoragePath,
    filter: Partial<Filter>
  ) => void
  getFilterLocalstorage: (path: FilterLocalStoragePath) => Filter
  clear: () => void
}

export const useFilterLocalStorage = (): UseFilterLocalStorage => {
  function getPath(path: FilterLocalStoragePath) {
    return `${path.project}.${path.view}`
  }
  function getFilterLocalstorage(path: FilterLocalStoragePath) {
    const filter = window.localStorage.getItem(getPath(path))

    if (!filter) return defaultFilter()

    return JSON.parse(filter)
  }

  function setFilterLocalstorage(
    path: FilterLocalStoragePath,
    filter: Partial<Filter>
  ) {
    const projectFilter = getFilterLocalstorage(path)
    if (projectFilter && Object.values(projectFilter).length) {
      window.localStorage.setItem(
        getPath(path),
        JSON.stringify({
          ...projectFilter,
          ...filter,
        })
      )
    } else {
      window.localStorage.setItem(
        getPath(path),
        JSON.stringify({ ...defaultFilter(), ...filter })
      )
    }
  }

  function clear() {
    const { projects } = useProfile()
    const views = [
      'deviationDelay',
      'dailyReportExternalV2',
      'dailyReportExternalV1',
      'dailyReportInternalV1',
    ]
    for (const project of projects.value) {
      for (const view of views) {
        window.localStorage.removeItem(getPath({ project: project.name, view }))
      }
    }
  }

  return { setFilterLocalstorage, getFilterLocalstorage, clear }
}
