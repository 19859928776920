import { instance } from '@/services/avik-api-gateway'
import { DeviationCancelProposal } from '@/types/deviation-cancel-proposal'
import { AxiosResponse } from 'axios'

interface FetchParams {
  from: string
  to: string
}

let abortController: AbortController

export function getDeviationCancelProposalAll(
  params: FetchParams
): Promise<AxiosResponse<DeviationCancelProposal[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }
  return instance.get(`/deviation-cancel-proposal-all`, {
    params,
    signal: abortController.signal,
  })
}
